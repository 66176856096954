<template>
  <div>
    <!-- banner -->
    <div class="home-banner"
         style="background: url('https://img0.baidu.com/it/u=4162443464,2854908495&fm=253') center center / cover no-repeat">
      <div class="banner-container">
        <!-- 联系方式 -->
        <h1 class="blog-title animate__animated animate__zoomIn">
          FU博客
        </h1>
        <!-- 一言 -->
        <div class="blog-intro">
          {{ easyTyperObj.output }} <span class="typed-cursor">|</span>
        </div>
      </div>
      <!-- 向下滚动 -->
      <div class="scroll-down" @click="scrollDown">
        <v-icon color="#fff" class="scroll-down-effects">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <!-- 主页文章 -->
    <v-row class="home-container">
      <v-col md="9" cols="12">
        <!--        &lt;!&ndash; 说说轮播 &ndash;&gt;-->
        <!--        <v-card class="animated zoomIn" v-if="talkList.length > 0">-->
        <!--          <Swiper :list="talkList" />-->
        <!--        </v-card>-->
        <v-card
            class="animate__animated animate__zoomIn article-card"
            style="border-radius: 12px 8px 8px 12px"
            v-for="(item, index) of articleList"
            :key="item.id"
        >
          <!-- 文章封面图 -->
          <div :class="isRight(index)">
            <router-link :to="'/articles/' + item.id">
              <v-img
                  class="on-hover"
                  width="100%"
                  height="100%"
                  :src="item.cover"
              />
            </router-link>
          </div>
          <!-- 文章信息 -->
          <div class="article-wrapper">
            <div style="line-height:1.4">
              <router-link :to="'/articles/' + item.id">
                {{ item.title }}
              </router-link>
            </div>
            <div class="article-info">
              <!-- 是否置顶 -->
              <span v-if="item.isTop === 1">
                <span style="color:#ff7242">
                  <i class="iconfont icon-briefcase"/> 置顶
                </span>
                <span class="separator">|</span>
              </span>
              <!-- 发表时间 -->
              <v-icon size="14">mdi-calendar-month-outline</v-icon>
              {{ DateFormat.dateTime(item.createTime) }}
              <span class="separator">|</span>
              <!-- 文章分类 -->
              <router-link :to="'/category/' + category.categoryId" v-for="category of item.categoryList" :key="category.categoryId"
                           class="mr-1" style="display:inline-block">
                <v-icon size="14">mdi-inbox-full</v-icon>
                {{ category.categoryName }}
              </router-link>
              <span class="separator">|</span>
              <!-- 文章标签 -->
              <router-link
                  style="display:inline-block"
                  :to="'/tag/' + tag.tagId"
                  class="mr-1"
                  v-for="tag of item.tagList"
                  :key="tag.tagId"
              >
                <v-icon size="14">mdi-tag-multiple</v-icon>
                {{ tag.tagName }}
              </router-link>
            </div>
            <!-- 文章内容 -->
            <div class="article-content">
              {{ item.summary }}
            </div>
          </div>
        </v-card>
        <!-- 无限加载 -->
        <v-infinite-scroll @load="fetchArticleData" empty-text="暂无更多">

        </v-infinite-scroll>
      </v-col>
      <!-- 博主信息 -->
      <v-col md="3" cols="12" class="d-md-block d-none">
        <div class="blog-wrapper">
          <v-card class="animate__animated animate__slideInRight blog-card mt-5">
            <div class="author-wrapper">
              <!-- 博主头像 -->
              <v-avatar size="110" image="https://barney-fu.oss-cn-beijing.aliyuncs.com/FuBlog/photo/UFWcvaJZ.jpg"/>
              <div style="font-size: 1.375rem;margin-top:0.625rem">
                锦书难书Q
              </div>
              <div style="font-size: 0.875rem;">
                Hello World
              </div>
            </div>
            <!-- 博客信息 -->
            <div class="blog-info-wrapper">
              <div class="blog-info-data">
                <router-link to="/archive">
                  <div style="font-size: 0.875rem">文章</div>
                  <div style="font-size: 1.25rem">
                    {{ blogCount.articleCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/category">
                  <div style="font-size: 0.875rem">分类</div>
                  <div style="font-size: 1.25rem">
                    {{ blogCount.categoryCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/tag">
                  <div style="font-size: 0.875rem">标签</div>
                  <div style="font-size: 1.25rem">{{ blogCount.tagCount }}</div>
                </router-link>
              </div>
            </div>
            <!-- 收藏按钮 -->
            <a class="collection-btn" @click="tip = true">
              <v-icon color="#fff" size="18" class="mr-1">mdi-bookmark</v-icon>
              加入书签
            </a>

          </v-card>
          <!-- 网站信息 -->
          <v-card class="blog-card animate__animated animate__slideInRight mt-5 big">
            <div class="web-info-title">
              <v-icon size="18">mdi-bell</v-icon>
              公告
            </div>
            <div style="font-size:0.875rem">
              公告内容
            </div>
          </v-card>
          <!-- 网站信息 -->
          <v-card class="blog-card animate__animated animate__slideInRight mt-5">
            <div class="web-info-title">
              <v-icon size="18">mdi-chart-line</v-icon>
              网站资讯
            </div>

          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import EasyTyper from "easy-typer-js";
import {onBeforeMount, reactive, ref} from "vue";
import axios from "axios";
import {DateFormat} from "../assets/js/DateFormat";
import {it} from "vuetify/locale";

export default {
  name: 'Home',
  computed: {
    it() {
      return it
    },
    DateFormat() {
      return DateFormat
    }
  },
  components: {},
  setup() {
    const easyTyperObj = reactive({
      output: "",
      isEnd: false,
      speed: 150,
      singleBack: false,
      sleep: 0,
      type: "rollback",
      backSpeed: 40,
      sentencePause: true
    })

    const articleFilter = reactive({
      status: "1",
      pageNum: 1,
      pageSize: 4
    })

    const articleList = reactive([])

    const blogCount = reactive({
      articleCount: 0,
      categoryCount: 0,
      tagCount: 0
    })
    const scrollDown = () => {
      window.scrollTo({
        behavior: "smooth",
        top: document.documentElement.clientHeight
      });
    }

    onBeforeMount(() => {
      // 一言Api进行打字机循环输出效果
      fetch("https://v1.hitokoto.cn/?c=i")
          .then(res => {
            return res.json();
          })
          .then(({hitokoto}) => {
            new EasyTyper(easyTyperObj, hitokoto);
          });
      axios.get("/article/blog/articles/count").then((response) => {
        blogCount.articleCount = response.data.data.articleCount;
        blogCount.categoryCount = response.data.data.categoryCount;
        blogCount.tagCount = response.data.data.tagCount;
      })
    })

    const isRight = (index) => {
      if (index % 2 === 0) {
        return "article-cover left-radius animate__animated animate__slideInRight";
      }
      return "article-cover right-radius animate__animated animate__slideInLeft";
    }

    const fetchArticleData = ({done}) => {
      // axios.post("/article/blog/articles/list", articleFilter).then((response) => {
      //   articleList.push(...response.data.data.list);
      //   articleFilter.pageNum++;
      //   console.log(articleList)
      //   if (response.data.data.list.length === 0) {
      //     console.log("到底了")
      //     done("empty")
      //   } else {
      //     done("ok");
      //   }
      //
      // })
      axios.post("/article/blog/articles/new-list", articleFilter).then((response) => {
        articleList.push(...response.data.data.list);
        articleFilter.pageNum++;
        console.log(articleList)
        if (response.data.data.list.length === 0) {
          console.log("到底了")
          done("empty")
        } else {
          done("ok");
        }

      })
    }

    return {
      easyTyperObj,
      articleList,
      blogCount,
      scrollDown,
      isRight,
      fetchArticleData
    }
  }
}
</script>

<style scoped>
.home-banner {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  height: calc(100vh + 60px);
  background-attachment: fixed;
  text-align: center;
  color: #fff !important;
  animation: header-effect 1s;
}

.banner-container {
  margin-top: 43vh;
  line-height: 1.5;
  color: #eee;
}

.scroll-down {
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.article-wrapper a:hover {
  color: #8e8cd8;
}

.scroll-down-effects {
  color: #eee !important;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  line-height: 1.5;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  animation: scroll-down-effect 1.5s infinite;
}

@keyframes scroll-down-effect {
  0% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
  50% {
    top: -16px;
    opacity: 1;
    filter: none;
  }
  100% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@media (min-width: 760px) {
  .blog-title {
    font-size: 2.5rem;
  }

  .blog-intro {
    font-size: 1.5rem;
  }

  .home-container {
    max-width: 1200px;
    margin: calc(100vh - 20px) auto 28px auto;
    padding: 0 5px;
  }

  .article-card {
    display: flex;
    align-items: center;
    height: 280px;
    width: 100%;
    margin-top: 20px;
  }

  .article-cover {
    overflow: hidden;
    height: 100%;
    width: 50%;
  }

  .on-hover {
    transition: all 0.6s;
  }

  .article-card:hover .on-hover {
    transform: scale(1.1);
  }

  .article-wrapper {
    padding: 0 2.5rem;
    width: 55%;
  }

  .article-wrapper a {
    font-size: 1.5rem;
    transition: all 0.3s;
  }
}

@media (max-width: 759px) {
  .blog-title {
    font-size: 26px;
  }

  .home-container {
    width: 100%;
    margin: calc(100vh + 66px) auto 0 auto;
  }

  .article-card {
    margin-top: 1rem;
  }

  .article-cover {
    border-radius: 8px 8px 0 0 !important;
    height: 230px !important;
    width: 100%;
  }

  .article-cover div {
    border-radius: 8px 8px 0 0 !important;
  }

  .article-wrapper {
    padding: 1.25rem 1.25rem 1.875rem;
  }

  .article-wrapper a {
    font-size: 1.25rem;
    transition: all 0.3s;
  }
}

.left-radius {
  border-radius: 8px 0 0 8px !important;
  order: 0;
}

.right-radius {
  border-radius: 0 8px 8px 0 !important;
  order: 1;
}

.article-wrapper {
  font-size: 14px;
}

.article-info {
  font-size: 95%;
  color: #858585;
  line-height: 2;
  margin: 0.375rem 0;
}

.article-info a {
  font-size: 95%;
  color: #858585 !important;
}

.article-content {
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-wrapper {
  position: sticky;
  top: 10px;
}

.blog-card {
  line-height: 2;
  padding: 1.25rem 1.5rem;
}

.author-wrapper {
  text-align: center;
}

.blog-info-wrapper {
  display: flex;
  justify-self: center;
  padding: 0.875rem 0;
}

.blog-info-data {
  flex: 1;
  text-align: center;
}

.blog-info-data a {
  text-decoration: none;
}

.collection-btn {
  text-align: center;
  z-index: 1;
  font-size: 14px;
  position: relative;
  display: block;
  background-color: #49b1f5;
  color: #fff !important;
  height: 32px;
  line-height: 32px;
  transition-duration: 1s;
  transition-property: color;
}

.collection-btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #ff7242;
  content: "";
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: scaleX(0);
  transform-origin: 0 50%;
}

.collection-btn:hover:before {
  transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
  transform: scaleX(1);
}

.author-avatar {
  transition: all 0.5s;
}

.author-avatar:hover {
  transform: rotate(360deg);
}

.web-info {
  padding: 0.25rem;
  font-size: 0.875rem;
}

.big i {
  color: #f00;
  animation: big 0.8s linear infinite;
}

@keyframes big {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
</style>