<template>
  <v-dialog v-model="store.state.forgetDialog" max-width="460" transition="dialog-top-transition">
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="store.state.forgetDialog = false">
        mdi-close
      </v-icon>
      <v-form v-model="forgetFormValid" fast-fail @submit.prevent="clickBtn">
        <div class="login-wrapper">
          <!-- 用户名 -->
          <v-text-field
              v-model="forgetForm.username"
              label="邮箱号"
              placeholder="请输入您的邮箱号"
              :rules="usernameRules"
              :counter="36"
              :clearable="true"
              variant="underlined"
              color="blue"
          />
          <!-- 验证码 -->
          <div class="mt-6 send-wrapper">
            <v-text-field
                v-model="forgetForm.code"
                label="验证码"
                type="number"
                placeholder="请输入6位验证码"
                :rules="codeRules"
                variant="underlined"
                color="blue"
            />
            <v-btn variant="text" class="mt-4" small :disabled="captcha.sendFlag" @click="sendCode">
              {{ captcha.codeMsg }}
            </v-btn>
          </div>
          <!-- 密码 -->
          <v-text-field
              v-model="forgetForm.password"
              class="mt-6"
              label="新密码"
              placeholder="请输入您的密码"
              :counter="16"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              variant="underlined"
              color="blue"
          />
          <!-- 注册按钮 -->
          <v-btn
              class="mt-6"
              :block="true"
              color="green"
              style="color:#fff"
              type="submit"
          >
            确定
          </v-btn>
          <!-- 登录 -->
          <div class="mt-7 login-tip">
            已有账号？<span @click="openLoginDialog">登录</span>
          </div>
        </div>
      </v-form>

    </v-card>
  </v-dialog>
  <v-snackbar class="animate__animated animate__headShake" v-model="showErrorToast.flag" location="top" :timeout="2000"
              color="#F56C6C">
    <i class="iconfont icon-globe" style="margin-right: 10px;"/>{{ showErrorToast.message }}
  </v-snackbar>
  <v-snackbar class="animate__animated animate__fadeInDown" v-model="showSuccessToast.flag" location="top"
              :timeout="2000"
              color="#46c583">
    <i class="iconfont icon-globe" style="margin-right: 10px;"/>{{ showSuccessToast.message }}
  </v-snackbar>
</template>

<script>
import {reactive, ref, watch} from "vue";
import store from "@/store";
import axios from "axios";
import md5 from "js-md5";
import {FuTool} from "@/assets/js/FuTool";

export default {
  name: "ForgetModel",
  computed: {
    store() {
      return store
    }
  },
  setup() {
    const forgetForm = reactive({
      username: "",
      password: "",
      code: ""
    });
    const captcha = reactive({
      codeMsg: "发送",
      sendFlag: true,
      countdown: 60
    })
    const showPassword = ref(false);

    const usernameRules = [
      value => {
        if (value) {
          return true;
        }
        return "邮箱不能为空！";
      },
      value => {
        if (value.length <= 36) {
          return true;
        }
        return "超出长度限制！"
      }
    ];
    const passwordRules = [
      value => {
        if (value) {
          return true;
        }
        return "密码不能为空！"
      },
      value => {
        if (value?.length <= 32) return true;
        return "密码不能超过32位！"
      }
    ];
    const codeRules = [
      value => {
        if (value) {
          return true;
        }
        return "验证码不能为空！"
      },
    ];

    const forgetFormValid = ref(false);
    const showErrorToast = reactive({
      flag: false,
      message: ""
    });
    const showSuccessToast = reactive({
      flag: false,
      message: ""
    });
    const emailValidation = ref(false);
    const openLoginDialog = () => {
      store.state.forgetDialog = false;
      store.state.loginDialog = true;
    }


    watch(() => store.state.forgetDialog, (value) => {
          if (value === false) {
            forgetForm.username = "";
            forgetForm.password = "";
            forgetForm.code = "";
          }
        }
    )

    watch(() => forgetForm.username, (username) => {
      const emailRule = /^[a-zA0-9_.-]+@[a-z0-9-]+(\.[a-z]+)*\.(com|cn|net|hk|mo|tw)$/i;
      captcha.sendFlag = !emailRule.test(username);
      emailValidation.value = emailRule.test(username);
    })

    watch(() => forgetForm.code, (code) => {
      const maxLength = 6;
      if (code.length > maxLength) {
        forgetForm.code = code.slice(0, maxLength);
      }
    })


    const sendCode = () => {
      captcha.sendFlag = true;
      let param = new URLSearchParams();
      param.append("email", forgetForm.username)
      param.append("codeType", "2");
      axios.post("/message/send-code", param).then((response) => {
        if (response.data.code === "0") {
          const timer = setInterval(() => {
            console.log(captcha.countdown)
            captcha.countdown--;
            captcha.codeMsg = captcha.countdown + "秒";
            if (captcha.countdown <= 0) {
              clearInterval(timer);
              captcha.codeMsg = "发送";
              captcha.countdown = 60;
              captcha.sendFlag = false;
              showSuccessToast.flag = false;
            }
          }, 1000)
          showSuccessToast.flag = true;
          showSuccessToast.message = "验证码发送成功！"
        }
      })
    }
    const clickBtn = () => {
      if (forgetFormValid.value === true) {
        //emailValidation.value = !captcha.sendFlag;
        console.log(emailValidation.value)
        if (emailValidation.value === false) {
          showErrorToast.flag = true;
          showErrorToast.message = "邮箱格式不正确！"
          return false;
        }
        if (forgetForm.code.length !== 6) {
          showErrorToast.flag = true;
          showErrorToast.message = "请输入正确的6位验证码！"
          return false;
        }

        forgetForm.password = md5(FuTool.salt(forgetForm.password));

        axios.post("/system/blog/user/forget-password", forgetForm).then((response) => {
          switch (response.data.code) {
            case "1001": {
              showErrorToast.flag = true;
              showErrorToast.message = "该用户不存在！"
              return false;
            }
            case "1005": {
              showErrorToast.flag = true;
              showErrorToast.message = "验证码已过期！"
              return false;
            }
            case "1006": {
              showErrorToast.flag = true;
              showErrorToast.message = "验证码错误！"
              return false;
            }
            case "1007": {
              showErrorToast.flag = true;
              showErrorToast.message = "新密码不能与旧密码相同！"
              return false;
            }
            case "0": {
              showSuccessToast.flag = true;
              showSuccessToast.message = "修改成功！";
              store.state.forgetDialog = false;
              break;
            }
            default: {
              showErrorToast.flag = true;
              showErrorToast.message = "出错了，请联系管理员！"
              return false;
            }
          }
        })
        return true;
      }
    }
    return {
      forgetForm,
      captcha,
      showPassword,
      usernameRules,
      passwordRules,
      codeRules,
      forgetFormValid,
      showErrorToast,
      showSuccessToast,
      emailValidation,
      sendCode,
      openLoginDialog,
      clickBtn,
    }
  }
}
</script>

<style scoped>
:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
}

:deep(input[type="number"]) {
  -moz-appearance: textfield;
}

</style>