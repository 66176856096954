import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import "@/assets/css/blog-index.css"
import "@/assets/css/iconfont.css"
import 'animate.css'
import axios from "axios";
import {Toast} from "@/assets/js/Toast";
import {SessionStorage} from "@/assets/js/SessionStorage";
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueMarkdownEditor).use(VMdPreview)
  .mount('#app')

axios.defaults.baseURL = process.env.VUE_APP_SERVER;
console.log('环境：', process.env.NODE_ENV);
console.log('服务端：', process.env.VUE_APP_SERVER);

/**
 * axios拦截器
 */
axios.interceptors.request.use(function (config) {
    console.log('请求参数：', config);
    const token = store.state.userInfo.token;
    if(token){
        config.headers.token = token;
        console.log("请求headers增加token：",token)
    }
    return config;
}, error => {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    console.log('返回结果：', response);
    return response;
}, error => {
    console.log('返回错误：', error);

    const response = error.response;
    const status = response.status;
    if(status === 401){
        //判断状态码为401 跳转到登录页
        console.log("未登录或登陆超时，跳到登录页")
        SessionStorage.set("USER",{});
        store.commit("userInfo",{})
        SessionStorage.set("MENU",[]);
        store.commit("userMenus",[])
        Toast.error("未登录或登录超时")
        router.push("/")
        router.go(0);
    }
    else if(status === 403){
        console.log("您的权限不足")
        SessionStorage.set("USER",{});
        store.commit("userInfo",{})
        SessionStorage.set("MENU",[]);
        store.commit("userMenus",[])
        Toast.error("您的权限不足")
        router.push("/");
        router.go(0);
    }

    return Promise.reject(error);
});
