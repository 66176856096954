import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/Home.vue'
import store from "@/store";
import {Toast} from "@/assets/js/Toast";
import {Authorization} from "@/assets/js/authorization";

const routes = [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    {
        path: '/',
        name: 'home',
        meta: {
            loginRequire: false,
            title:"首页"
        },
        component: () => import('../views/Home.vue')
    },
    {
        path: "/articles/:id",
        meta: {
            loginRequire: false
        },
        component: () => import('../views/Article.vue')
    },
    {
        path: "/category/:categoryId",
        meta: {
            loginRequire: false
        },
        component: () => import('../views/ArticleList.vue')
    },
    {
        path: "/tag/:tagId",
        meta: {
            loginRequire: false
        },
        component: () => import('../views/ArticleList.vue')
    },
    {
        path: '/test',
        name: 'test',
        meta: {
            loginRequire: true,
            title: "测试"
        },
        component: () => import('../views/Test.vue')
    },
    {
        path: '/talks',
        name: 'talks',
        meta: {
            loginRequire: false,
            title: "说说"
        },
        component: () => import('../views/TalkList.vue')
    }, {
        path: '/talks/:id',
        name: 'talk',
        meta: {
            loginRequire: false,
            title: "说说"
        },
        component: () => import('../views/Talk.vue')
    },
    {
        path: '/category',
        name: 'category',
        meta: {
            loginRequire: false,
            title: "分类"
        },
        component: () => import('../views/CategoryList.vue')
    },
    {
        path: '/tag',
        name: 'tag',
        meta: {
            loginRequire: false,
            title: "标签"
        },
        component: () => import('../views/TagList.vue')
    },
    {
        path: '/album',
        name: 'album',
        meta: {
            loginRequire: false,
            title: "相册"
        },
        component: () => import('../views/Album.vue')
    }, {
        path: '/album/:albumId',
        name: 'photo',
        meta: {
            loginRequire: false
        },
        component: () => import('../views/Photo.vue')
    },
    {
        path: '/message',
        name: 'message',
        meta: {
            loginRequire: false,
            title: "留言版"
        },
        component: () => import('../views/Message.vue')
    }, {
        path: '/about',
        name: 'about',
        meta: {
            loginRequire: false,
            title: "关于"
        },
        component: () => import('../views/About.vue')
    }, {
        path: '/archive',
        name: 'archive',
        meta: {
            loginRequire: false,
            title: "归档"
        },
        component: () => import('../views/Archive.vue')
    }, {
        path: '/center',
        name: 'center',
        meta: {
            loginRequire: false,
            title: "个人中心"
        },
        component: () => import('../views/Center.vue')
    },
    {
        path: '/test',
        name: 'test',
        meta: {
            loginRequire: true
        },
        component: () => import('../views/Test.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


// 路由登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    // 要不要对meta.loginRequire属性做监控拦截
    if (to.matched.some(function (item) {
        console.log(item, "是否需要登录校验：", item.meta.loginRequire || false);
        return item.meta.loginRequire
    })) {
        const userInfo = store.state.userInfo;
        console.log("页面登录校验开始：", userInfo);
        if (!userInfo.token) {
            console.log("用户未登录或登录超时！");
            Toast.error("用户未登录或登录超时！");
            next('/');
        } else {
            if (Authorization.hasMenuRouter(to.path)) {
                next();
            } else {
                console.log("您没有对应的权限！");
                Toast.error("您没有对应的权限！");
                console.error("跳转到主页");
                next('/');
            }
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    window.scrollTo({
        top: 0,
        behavior: "instant"
    });
});
export default router
