import store from "@/store";
import {FuTool} from "@/assets/js/FuTool";

export const Authorization = {
  hasMenuRouter: (router) => {
    const loginUser = store.state.userInfo;
    if (FuTool.isNotEmpty(loginUser)) {
      const menuCatalog = loginUser.menuList;
      const menuList = [];
      menuCatalog.forEach(catalog => {
        if (FuTool.isNotEmpty(catalog.children)) {
          catalog.children.forEach(item => {
            menuList.push(item);
          })
        }
        menuList.push(catalog);
      })
      let flag = false;
      menuList.forEach(menu => {
        if (menu.path === router) {
          flag = true;
        }
      })
      return flag;
    } else return false;
  },

  hasResource: (resourceId) => {
    const loginUser = store.state.userInfo;
    if (FuTool.isNotEmpty(loginUser)) {
      const resourceCatalog = loginUser.resourceList;
      const resourceList = [];
      resourceCatalog.forEach(catalog => {
        if (FuTool.isNotEmpty(catalog.children)) {
          catalog.children.forEach(item => {
            resourceList.push(item);
          })
        }
        resourceList.push(catalog);
      })
      let flag = false;
      resourceList.forEach(resource => {
        if (resource.id === resourceId) {
          flag = true;
        }
      })
      return flag;
    } else return false;
  }
} 
