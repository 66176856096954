export const FuTool = {

  salt: (obj) => {
    return "FuBlogSalt ~ 001." + obj;
  },
  /**
   * 空校验 null或""都返回true
   */
  isEmpty: (obj) => {
    if ((typeof obj == 'string')) {
      return !obj || obj.replace(/\s+/g, "") === ""
    } else {
      return (!obj || JSON.stringify(obj) === "{}" || obj.length === 0);
    }
  },

  /**
   * 非空校验
   */
  isNotEmpty: (obj) => {
    return !FuTool.isEmpty(obj);
  },

  /**
   * 长度校验
   */
  isLength: (str, min, max) => {
    if ((typeof str == 'string')) {
      return str.trim().length >= min && str.trim().length <= max;
    }
    else return false;
  },
  

  /**
   * 移除对象数组中的对象
   * @param array
   * @param obj
   * @returns {number}
   */
  removeObj: (array, obj) => {
    let index = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i] === obj) {
        array.splice(i, 1);
        index = i;
        break;
      }
    }
    return index;
  }
}
