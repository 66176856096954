import {createStore} from 'vuex'
import {SessionStorage} from "@/assets/js/SessionStorage";

const USER = "USER";
const MENU = "MENU";
const MOBILE_MENU = "MOBILE_MENU";
export default createStore({
    state: {
        loginDialog: false,
        registerDialog: false,
        forgetDialog: false,
        sideDrawer: false,
        userInfo: SessionStorage.get(USER) || {},
        userMenus: SessionStorage.get(MENU) || [],
        mobileMenus: SessionStorage.get(MOBILE_MENU) || [],
        topicInfo: {},
        commentList: [],
    },
    getters: {},
    mutations: {
        login(state, user) {
            sessionStorage.setItem('token', user.token)
            state.userInfo = user
            SessionStorage.set(USER, user)
        },
        logout(state) {
            sessionStorage.removeItem('token');
            state.userInfo = {};
            SessionStorage.remove(USER);
            state.userMenus = [];
            SessionStorage.remove(MENU);
            state.mobileMenus = [];
            SessionStorage.remove(MOBILE_MENU);
        },
        saveUserMenus(state, userMenus) {
            state.userMenus = userMenus
            SessionStorage.set(MENU, userMenus)
        },
        saveMobileMenus(state, mobileMenus) {
            state.mobileMenus = mobileMenus
            SessionStorage.set(MOBILE_MENU, mobileMenus)
        },
        saveUserAvatar(state, avatar) {
            state.userInfo.avatar = avatar
            SessionStorage.set(USER, state.userInfo)
        },
    },
    actions: {},
    modules: {}
})
