<template>
  <v-navigation-drawer
      v-model="store.state.sideDrawer"
      width="250"
      :disable-resize-watcher="true"
      location="right"
      scrim="rgba(0,0,0,0.8)"
      order="1"
  >
    <!-- 博主介绍 -->
    <div class="blogger-info">
      <v-avatar size="110" style="margin-bottom:0.5rem"
                image="https://barney-fu.oss-cn-beijing.aliyuncs.com/FuBlog/FU.png"/>
    </div>
    <!-- 博客信息 -->
    <div class="blog-info-wrapper">
      <div class="blog-info-data">
        <router-link to="/archive">
          <div style="font-size: 0.875rem">文章</div>
          <div style="font-size: 1.125rem;">
            {{ blogCount.articleCount }}
          </div>
        </router-link>
      </div>
      <div class="blog-info-data">
        <router-link to="/category">
          <div style="font-size: 0.875rem">分类</div>
          <div style="font-size: 1.125rem">
            {{ blogCount.categoryCount }}
          </div>
        </router-link>
      </div>
      <div class="blog-info-data">
        <router-link to="/tag">
          <div style="font-size: 0.875rem">标签</div>
          <div style="font-size: 1.125rem">
            {{ blogCount.tagCount }}
          </div>
        </router-link>
      </div>
    </div>
    <hr/>
    <!-- 页面导航 -->
    <div class="menu-container">
      <div class="menus-item">
        <router-link to="/">
          <i class="iconfont iconzhuye"/> 首页
        </router-link>
      </div>
      <div v-if="store.state.mobileMenus">
        <div class="menus-item" v-for="(item,index) in store.state.mobileMenus" :key="index">
          <router-link :to="item.path">
            <i :class="handleMenuIcon(item.icon)"/> {{ item.name }}
          </router-link>
        </div>
      </div>
      <div class="menus-item" @click="store.state.loginDialog = true" v-if="!store.state.userInfo.avatar">
        <i class="iconfont icondenglu"/> 登录
      </div>
      <div v-else>
        <div class="menus-item">
          <router-link to="/center">
            <i class="iconfont icongerenzhongxin"/> 个人中心
          </router-link>
        </div>
        <div class="menus-item" @click="logout">
          <i class="iconfont icontuichu"/> 退出
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import store from "@/store";
import {onBeforeMount, reactive} from "vue";
import axios from "axios";
import {Toast} from "@/assets/js/Toast";
import router from "@/router";

export default {
  name: "SideNavigation",
  computed: {
    store() {
      return store
    }
  },
  setup() {
    const drawer = store.state.sideDrawer;
    const handleMenuIcon = (icon) => {
      return "iconfont " + icon;
    }
    const blogCount = reactive({
      articleCount: 0,
      categoryCount: 0,
      tagCount: 0
    })
    onBeforeMount(() => {
      axios.get("/article/blog/articles/count").then((response) => {
        blogCount.articleCount = response.data.data.articleCount;
        blogCount.categoryCount = response.data.data.categoryCount;
        blogCount.tagCount = response.data.data.tagCount;
      })
    })

    const logout = () => {
      console.log("退出登录")
      //console.log(store.state.userInfo.id)
      //store.state.loginDialog = true;
      axios.post("/system/blog/user/logout/" + store.state.userInfo.id).then((response) => {
        Toast.success("退出登录成功！");
        store.commit('logout');
        console.log(store.state.userInfo)
        router.push("/");

      })
    }
    return {
      drawer,
      blogCount,
      handleMenuIcon,
      logout
    }
  }
}
</script>

<style scoped>
.blogger-info {
  padding: 26px 30px 0;
  text-align: center;
}

.blog-info-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 10px 0;
}

.blog-info-data {
  flex: 1;
  line-height: 2;
  text-align: center;
}

hr {
  border: 2px dashed #d2ebfd;
  margin: 20px 0;
}

.menu-container {
  padding: 0 10px 40px;
  animation: 0.8s ease 0s 1 normal none running sidebarItem;
}

.menus-item a {
  padding: 6px 30px;
  display: block;
  line-height: 2;
}

.menus-item i {
  margin-right: 2rem;
}

@keyframes sidebarItem {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>