<template>
  <v-dialog v-model="store.state.registerDialog" max-width="460" transition="dialog-top-transition">
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="store.state.registerDialog = false">
        mdi-close
      </v-icon>
      <v-form fast-fail v-model="registerFormValid" @submit.prevent="register">
        <div class="login-wrapper">
          <!-- 用户名 -->
          <v-text-field
              v-model="registerForm.username"
              label="邮箱号"
              placeholder="请输入您的邮箱号"
              :clearable="true"
              :rules="usernameRules"
              :counter="36"
              variant="underlined"
              color="blue"
          />
          <!-- 验证码 -->
          <div class="mt-6 send-wrapper">
            <v-text-field
                v-model="registerForm.code"
                label="验证码"
                placeholder="请输入6位验证码"
                :rules="codeRules"
                type="number"
                variant="underlined"
                color="blue"
            />
            <v-btn variant="text" class="mt-4" small :disabled="captcha.sendFlag" @click="sendCode">
              {{ captcha.codeMsg }}
            </v-btn>
          </div>
          <!-- 密码 -->
          <v-text-field
              v-model="registerForm.password"
              class="mt-6"
              label="密码"
              placeholder="请输入您的密码"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              variant="underlined"
              color="blue"
          />
          <!-- 注册按钮 -->
          <v-btn
              class="mt-6"
              :block="true"
              color="red"
              style="color:#fff"
              type="submit"
          >
            注册
          </v-btn>
          <!-- 登录 -->
          <div class="mt-7 login-tip">
            已有账号？<span @click="openLoginDialog">登录</span>
          </div>
        </div>
      </v-form>

    </v-card>
  </v-dialog>
  <LoginModel ref="loginRef"/>
  <v-snackbar class="animate__animated animate__headShake" v-model="showErrorToast.flag" location="top" :timeout="2000"
              color="#F56C6C">
    <i class="iconfont icon-globe" style="margin-right: 10px;"/>{{ showErrorToast.message }}
  </v-snackbar>
  <v-snackbar class="animate__animated animate__fadeInDown" v-model="showSuccessToast.flag" location="top"
              :timeout="2000"
              color="#46c583">
    <i class="iconfont icon-globe" style="margin-right: 10px;"/>{{ showSuccessToast.message }}
  </v-snackbar>
</template>

<script>
import {reactive, ref, watch} from "vue";
import store from "@/store";
import axios from "axios";
import md5 from "js-md5";
import {FuTool} from "@/assets/js/FuTool";
import LoginModel from "@/components/model/LoginModel.vue";

export default {
  name: "RegisterModel",
  computed: {
    store() {
      return store
    }
  },
  components: {
    LoginModel
  },
  setup() {
    const registerForm = reactive({
      username: "",
      password: "",
      code: "",
      originalPassword: ""
    });
    const captcha = reactive({
      codeMsg: "发送",
      sendFlag: true,
      countdown: 60
    })
    const showPassword = ref(false);
    const usernameRules = [
      value => {
        if (value) {
          return true;
        }
        return "邮箱不能为空！";
      },
      value => {
        if (value.length <= 36) {
          return true;
        }
        return "超出长度限制！"
      }
    ];
    const passwordRules = [
      value => {
        if (value) {
          return true;
        }
        return "密码不能为空！"
      },
      value => {
        if (value?.length <= 32) return true;
        return "密码不能超过32位！"
      }
    ];
    const codeRules = [
      value => {
        if (value) {
          return true;
        }
        return "验证码不能为空！"
      },
    ];
    const registerFormValid = ref(false);
    const showSuccessToast = reactive({
      flag: false,
      message: ""
    });
    const showErrorToast = reactive({
      flag: false,
      message: ""
    });
    const emailValidation = ref(false);
    const loginRef = ref(null);
    const openLoginDialog = () => {
      store.state.registerDialog = false;
      store.state.loginDialog = true;
    }
    watch(() => store.state.registerDialog, (value) => {
          if (value === false) {
            registerForm.username = "";
            registerForm.password = "";
            registerForm.code = "";
          }
        }
    )

    watch(() => registerForm.username, (username) => {
      const emailRule = /^[a-zA0-9_.-]+@[a-z0-9-]+(\.[a-z]+)*\.(com|cn|net|hk|mo|tw)$/i;
      captcha.sendFlag = !emailRule.test(username);
      emailValidation.value = emailRule.test(username);
    })

    watch(() => registerForm.code, (code) => {
      const maxLength = 6;
      if (code.length > maxLength) {
        registerForm.code = code.slice(0, maxLength);
      }
    })

    const sendCode = () => {
      captcha.sendFlag = true;
      let param = new URLSearchParams();
      param.append("email", registerForm.username)
      param.append("codeType", "0");
      axios.post("/message/send-code", param).then((response) => {
        if (response.data.code === "0") {
          const timer = setInterval(() => {
            console.log(captcha.countdown)
            captcha.countdown--;
            captcha.codeMsg = captcha.countdown + "秒";
            if (captcha.countdown <= 0) {
              clearInterval(timer);
              captcha.codeMsg = "发送";
              captcha.countdown = 60;
              captcha.sendFlag = false;
              showSuccessToast.flag = false;
            }
          }, 1000)
          showSuccessToast.flag = true;
          showSuccessToast.message = "验证码发送成功！"
        }
      })
    }
    const register = () => {
      if (registerFormValid.value === true) {
        //emailValidation.value = !captcha.sendFlag;
        console.log(emailValidation.value)
        if (emailValidation.value === false) {
          showErrorToast.flag = true;
          showErrorToast.message = "邮箱格式不正确！"
          return false;
        }
        if (registerForm.code.length !== 6) {
          showErrorToast.flag = true;
          showErrorToast.message = "请输入正确的6位验证码！"
          return false;
        }
        registerForm.originalPassword = registerForm.password;
        registerForm.password = md5(FuTool.salt(registerForm.password));

        axios.post("/system/blog/user/register", registerForm).then((response) => {
          switch (response.data.code) {
            case "1004": {
              showErrorToast.flag = true;
              showErrorToast.message = "该邮箱已被注册！"
              return false;
            }
            case "1005": {
              showErrorToast.flag = true;
              showErrorToast.message = "验证码已过期！"
              return false;
            }
            case "1006": {
              showErrorToast.flag = true;
              showErrorToast.message = "验证码错误！"
              return false;
            }
            case "0": {
              showSuccessToast.flag = true;
              showSuccessToast.message = "注册成功！";
              store.state.registerDialog = false;
              //TODO 注册成功后直接登录
              loginRef.value.loginForm.username = registerForm.username;
              loginRef.value.loginForm.password = registerForm.originalPassword;
              loginRef.value.doLogin();
              loginRef.value.loginForm.username = "";
              loginRef.value.loginForm.password = "";
              break;
            }
            default: {
              showErrorToast.flag = true;
              showErrorToast.message = "出错了，请联系管理员！"
              return false;
            }
          }
        })
        return true;
      }
    }
    return {
      registerForm,
      captcha,
      showPassword,
      usernameRules,
      passwordRules,
      codeRules,
      registerFormValid,
      showErrorToast,
      showSuccessToast,
      emailValidation,
      loginRef,
      sendCode,
      openLoginDialog,
      register
    }
  }
}
</script>

<style scoped>
:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
}

:deep(input[type="number"]) {
  -moz-appearance: textfield;
}
</style>