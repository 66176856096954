<template>
  <v-app>
    <Navigation></Navigation>
    <!-- 侧边导航栏 -->
    <SideNavigation></SideNavigation>
    <!--    <Demo></Demo>-->
    <v-main>
      <router-view/>
    </v-main>

    <LoginModel></LoginModel>
    <RegisterModel></RegisterModel>
    <ForgetModel></ForgetModel>
  </v-app>
</template>

<script>

import Navigation from "@/components/header/Navigation.vue";
import LoginModel from "@/components/model/LoginModel.vue";
import RegisterModel from "@/components/model/RegisterModel.vue";
import ForgetModel from "@/components/model/ForgetModel.vue";
import SideNavigation from "@/components/header/SideNavigation.vue";

export default {
  name: 'App',
  components: {SideNavigation, ForgetModel, RegisterModel, LoginModel, Navigation},

  data: () => ({
    //
  }),
}
</script>
