<template>
  <v-dialog v-model="store.state.loginDialog" max-width="460" transition="dialog-top-transition" persistent>
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="store.state.loginDialog = false">
        mdi-close
      </v-icon>
      <v-tabs
          v-model="tab"
          color="deep-purple-accent-4"
          align-tabs="center"
      >
        <v-tab value="usernameLogin">邮箱登录</v-tab>
        <v-tab value="mobilePhoneLogin">用户名登录</v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="usernameLogin">
          <v-form fast-fail @submit.prevent="login(true)" v-model="loginFormValid">
            <div class="login-wrapper">
              <!-- 邮箱 -->
              <v-text-field
                  v-model="loginForm.username"
                  :rules="usernameRules"
                  label="邮箱"
                  placeholder="请输入您的邮箱"
                  :clearable="true"
                  color="blue"
                  variant="underlined"
                  :counter="36"
              />
              <!-- 密码 -->
              <v-text-field
                  v-model="loginForm.password"
                  class="mt-7"
                  label="密码"
                  :counter="16"
                  :rules="passwordRules"
                  placeholder="请输入您的密码"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  color="blue"
                  variant="underlined"
              />
              <!-- 按钮 -->
              <v-btn
                  class="mt-7"
                  :block="true"
                  color="blue"
                  style="color:#fff"
                  type="submit"
              >
                登录
              </v-btn>
              <!-- 注册和找回密码 -->
              <div class="mt-10 login-tip">
                <span @click="openRegisterDialog">立即注册</span>
                <span class="float-right" @click="openForgetDialog">忘记密码?</span>
              </div>
            </div>
          </v-form>
        </v-window-item>
        <v-window-item value="mobilePhoneLogin">
          <v-form fast-fail @submit.prevent="login(false)" v-model="loginFormValid">
            <div class="login-wrapper">
              <!-- 用户名 -->
              <v-text-field
                  v-model="loginForm.username"
                  label="用户名"
                  placeholder="请输入您的用户名"
                  :clearable="true"
                  color="blue"
                  variant="underlined"
                  :counter="36"
              />
              <!-- 密码 -->
              <v-text-field
                  v-model="loginForm.password"
                  class="mt-7"
                  label="密码"
                  :counter="16"
                  :rules="passwordRules"
                  placeholder="请输入您的密码"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  color="blue"
                  variant="underlined"
              />
              <!-- 按钮 -->
              <v-btn
                  class="mt-7"
                  :block="true"
                  color="blue"
                  style="color:#fff"
                  type="submit"
              >
                登录
              </v-btn>
              <!-- 注册和找回密码 -->
              <div class="mt-10 login-tip">
                用户名登录方式暂不支持注册，请异步邮箱登录
                <!--                <span @click="openRegisterDialog">立即注册</span>-->
                <!--                <span class="float-right" @click="openForgetDialog">忘记密码?</span>-->
              </div>
            </div>
          </v-form>
        </v-window-item>
      </v-window>
      <Captcha ref="captchaRef" @success="handleSuccess"/>
    </v-card>
  </v-dialog>
  <v-snackbar class="animate__animated animate__headShake" v-model="showErrorToast.flag" location="top" :timeout="2000"
              color="#F56C6C">
    <i class="iconfont icon-globe" style="margin-right: 10px;"/>{{ showErrorToast.message }}
  </v-snackbar>

</template>

<script>
import {reactive, ref, watch} from "vue";
import store from "@/store";
import {Toast} from "@/assets/js/Toast";
import axios from "axios";
import md5 from "js-md5";
import {FuTool} from "@/assets/js/FuTool";
import Captcha from "@/components/Captcha.vue";

export default {
  name: "LoginModel",
  computed: {
    store() {
      return store
    }
  },
  components: {Captcha},
  setup() {
    const loginForm = reactive({
      username: "",
      password: ""
    });
    const captchaRef = ref(null);
    const tab = ref(null);
    const loginFormValid = ref(false);
    const showPassword = ref(false);
    const showErrorToast = reactive({
      flag: false,
      message: ""
    });
    const emailValidation = ref(false);
    const errTimes = ref(0);
    const usernameRules = [
      value => {
        if (value) {
          return true;
        }
        return "邮箱不能为空！";
      },
      value => {
        if (value.length <= 36) {
          return true;
        }
        return "超出长度限制！"
      }
    ];
    const passwordRules = [
      value => {
        if (value) {
          return true;
        }
        return "密码不能为空！"
      },
      value => {
        if (value?.length <= 32) return true;
        return "密码不能超过32位！"
      }
    ];

    watch(() => store.state.loginDialog, (value) => {
          if (value === false) {
            loginForm.username = "";
            loginForm.password = "";
          }
        }
    )
    const demo = () => {
      Toast.success("Hello World");
    }

    const openRegisterDialog = () => {
      store.state.loginDialog = false;
      store.state.registerDialog = true;
    }
    const openForgetDialog = () => {
      store.state.loginDialog = false;
      store.state.forgetDialog = true;
    }
    const login = (emailType) => {
      if (loginFormValid.value === true) {
        if (emailType === true) {
          const emailRule = /^[a-zA0-9_.-]+@[a-z0-9-]+(\.[a-z]+)*\.(com|cn|net|hk|mo|tw)$/i;
          emailValidation.value = emailRule.test(loginForm.username);
          console.log(emailValidation.value)
          if (emailValidation.value === false) {
            showErrorToast.flag = true;
            showErrorToast.message = "邮箱格式不正确！";
            loginForm.password = "";
            return false;
          }
        }
        console.log(errTimes.value)
        if (errTimes.value <= 3) {
          doLogin();
        } else {
          // showCaptcha(captchaTypeArray.value[Math.floor(Math.random() * 2)])
          captchaRef.value.button.click();
        }
        return true;
      }

    }

    const doLogin = () => {
      loginForm.password = md5(FuTool.salt(loginForm.password));
      axios.post("/system/blog/user/login", loginForm).then((response) => {
        switch (response.data.code) {
          case "1002": {
            showErrorToast.flag = true;
            showErrorToast.message = "用户名或密码不正确！"
            loginForm.password = "";
            errTimes.value++;
            return false;
          }
          case "0": {
            console.log("登录成功！")
            store.state.loginDialog = false;
            Toast.success("登录成功！欢迎您：" + response.data.data.nickname)
            console.log(response.data.data)
            store.commit('login', response.data.data)
            store.commit("saveUserMenus", response.data.data.menuList)
            const menuList = response.data.data.menuList;
            const mobileMenus = [];
            for (let i = 0; i < menuList.length; i++) {
              if (FuTool.isEmpty(menuList[i].children)) {
                mobileMenus.push(menuList[i])
              }
              if (FuTool.isNotEmpty(menuList[i].children)) {
                for (let j = 0; j < menuList[i].children.length; j++) {
                  mobileMenus.push(menuList[i].children[j])
                }
              }
            }
            store.commit("saveMobileMenus", mobileMenus);
            console.log(mobileMenus)
            break;
          }
          default: {
            showErrorToast.flag = true;
            showErrorToast.message = "出错了，请联系管理员！";
            errTimes.value++;
            loginForm.password = "";
            return false;
          }
        }
      })
    }

    const handleSuccess = () => {
      console.log("验证成功")
      doLogin();
    }
    return {
      loginForm,
      tab,
      loginFormValid,
      showPassword,
      showErrorToast,
      usernameRules,
      passwordRules,
      captchaRef,
      demo,
      login,
      openRegisterDialog,
      openForgetDialog,
      handleSuccess,
      doLogin
    }
  }
}
</script>

<style scoped>

</style>