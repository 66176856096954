<template>
  <div id="captcha-button" ref="button" style="display: none">点击弹出验证码B</div>
  <div id="captcha-element"></div>
</template>

<script>
import {getCurrentInstance, onBeforeMount, onBeforeUnmount, ref} from "vue";
import axios from "axios";

export default {
  name: "Captcha",
  emits: ["success"],
  setup() {
    let captcha = null;
    const button = ref(null);
    const captchaInfo = {
      captchaVerifyParam: null,
      sceneId: null
    }
    const instance = getCurrentInstance();
    onBeforeMount(() => {
      initAliyunCaptcha({
        SceneId: '16h4autt', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: 'gjqn4h', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
    })
    const captchaVerifyCallback = (captchaVerifyParam) => {
      return new Promise((resolve) => {
        captchaInfo.captchaVerifyParam = captchaVerifyParam;
        captchaInfo.sceneId = '16h4autt'
        // 1.向后端发起业务请求，获取验证码验证结果和业务结果
        axios.post("/system/captcha/verify", captchaInfo).then((response) => {
          console.log(response)
          // 2.构造标准返回参数
          const verifyResult = {
            captchaResult: response.data.data.body.result.verifyResult, // 验证码验证是否通过，boolean类型，必选
            bizResult: true,
            // bizResult: 从result获取您的业务验证结果, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
          }
          console.log(verifyResult)
          resolve(verifyResult);
        })
      })
    }

    const onBizResultCallback = (bizResult) => {
      console.log('onBizResultCallback +', bizResult)
      instance.emit("success")
    }
    const getInstance = (instance) => {
      captcha = instance;
    }

    onBeforeUnmount(() => {
      // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
      document.getElementById('aliyunCaptcha-mask')?.remove();
      document.getElementById('aliyunCaptcha-window-popup')?.remove();
    })
    return {
      captchaVerifyCallback,
      onBizResultCallback,
      getInstance,
      button,
    }
  }
}
</script>

<style scoped>

</style>
