<template>
  <v-app-bar :class="navClass" :flat="true" scroll-behavior="hide" scroll-threshold="60" height="60" order="2">
    <!-- 手机端导航栏 -->
    <div class="d-md-none nav-mobile-container">
      <div style="font-size:18px;font-weight:bold">
        <router-link to="/">
          FU博客
        </router-link>
      </div>
      <div style="margin-left:auto">
        <a @click="openSideBar" style="margin-left:10px;font-size:20px">
          <i class="iconfont iconhanbao"/> 菜单
        </a>
      </div>
    </div>
    <!-- 电脑导航栏 -->
    <div class="d-md-block d-none nav-container">
      <div class="float-left blog-title">
          <span @click="router().replace('/')">
            首页
          </span>
      </div>
      <div class="float-right nav-title">
        <!--        <div class="menus-item">-->
        <!--            <span class="menu-btn">-->
        <!--              <i class="iconfont icon-caidan"/> 搜索-->
        <!--            </span>-->
        <!--        </div>-->
        <div class="menus-item">
            <span class="menu-btn" @click="router().replace('/')">
              <i class="iconfont icon-tuichudenglu"/> 首页
            </span>
        </div>
        <div v-if="store.state.userMenus">
          <div class="menus-item" v-for="(item,index) in store.state.userMenus" :key="index">
            <div v-if="item.children.length !== 0">
              <v-menu open-on-hover offset="12">
                <template v-slot:activator="{ props }">
                  <span class="menu-btn" v-bind="props">
                    <i :class="handleMenuIcon(item.icon)"/> {{ item.name }}
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(subItem,index) in item.children" :key="index" :link="true"
                               :base-color="btnColor[index]"
                               @mouseenter="handleBtnColor(index,1)" @mouseleave="handleBtnColor(index,2)">
                    <v-list-item-title class="submenu-item" @click="routingRelay(subItem)">
                      <i :class="handleMenuIcon(subItem.icon)"/> {{ subItem.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <span class="menu-btn" @click="routingRelay(item)">
                <i :class="handleMenuIcon(item.icon)"/> {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="menus-item">
          <div @click="openLoginDialog" v-if="!store.state.userInfo.id">
            <span class="menu-btn">
              <i class="iconfont icon-caidan"/> 登录
            </span>
          </div>
          <template v-else>
            <v-menu open-on-hover offset="12">
              <template v-slot:activator="{ props }">
                <img
                    v-bind="props"
                    class="user-avatar"
                    :src="store.state.userInfo.avatar"
                    height="30"
                    width="30"
                    alt=""/>
              </template>
              <v-list>
                <v-list-item v-for="(item,index) in btnTitle3" :key="index" :link="true" :base-color="btnColor[index]"
                             @mouseenter="handleBtnColor(index,1)" @mouseleave="handleBtnColor(index,2)">
                  <v-list-item-title class="submenu-item" @click="routingRelay(item)">
                    <i class="iconfont icon-caidan"/> {{ item }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </div>
    </div>
  </v-app-bar>

</template>
<script>
import {onBeforeMount, reactive, ref} from "vue";
import store from "@/store";
import axios from "axios";
import {Toast} from "@/assets/js/Toast";
import router from "@/router";

export default {
  name: "Navigation",
  computed: {
    store() {
      return store
    }
  },
  methods: {
    router() {
      return router
    }
  },
  setup() {
    const navClass = ref("");
    const btnColor = reactive([]);
    const btnTitle = reactive(["归档", "分类", "标签"]);
    const btnTitle2 = reactive(["相册", "说说", "应用", "电影", "打印机", "赤壁赋", "琵琶行"]);
    const btnTitle3 = reactive(["个人中心", "退出登录"]);
    const scroll = () => {
      /**
       * 计算滚动条顶部到视图窗口的y轴距离，三个都用上为了兼容，避免在某种情况下会失效
       */
      let scrollTop =
          window.scrollY ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if (scrollTop > 60) {
        navClass.value = "nav-fixed";
      } else {
        navClass.value = "nav";
      }
    }

    onBeforeMount(() => {
      navClass.value = "nav"
      window.addEventListener("scroll", scroll);
    })

    const handleBtnColor = (index, type) => {
      if (type === 1) {
        btnColor[index] = "blue";
      } else btnColor[index] = "";
    }
    const openLoginDialog = () => {
      store.state.loginDialog = true;
    }

    const handleMenuIcon = (icon) => {
      return "iconfont " + icon;
    }
    const routingRelay = (item) => {
      //console.log(typeof(item))
      if (item instanceof Object) {
        console.log(item.path)
        router.push(item.path)
      }
      if (typeof (item) === 'string') {
        switch (item) {
          case "退出登录": {
            console.log("退出登录")
            //console.log(store.state.userInfo.id)
            //store.state.loginDialog = true;
            axios.post("/system/blog/user/logout/" + store.state.userInfo.id).then((response) => {
              Toast.success("退出登录成功！");
              store.commit('logout');
              console.log(store.state.userInfo)
              router.push("/");
            })
            break;
          }
          case "个人中心": {
            console.log("个人中心")
            router.push("/center");
            break;
          }
          default: {
            console.log(item);
            break;
          }
        }
      }
    }
    const openSideBar = () => {
      store.state.sideDrawer = !store.state.sideDrawer;
      console.log(store.state.sideDrawer)
      console.log("打开侧边栏")
    }
    return {
      navClass,
      btnColor,
      btnTitle,
      btnTitle2,
      btnTitle3,
      handleMenuIcon,
      scroll,
      handleBtnColor,
      openLoginDialog,
      routingRelay,
      openSideBar
    }
  }
}

</script>
<style scoped>
i {
  margin-right: 4px;
}

ul {
  list-style: none;
}

.nav {
  background: rgba(0, 0, 0, 0) !important;
}
.nav span {
  color: #eee !important;
}

.nav .menu-btn {
  text-shadow: 0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
}

.nav .blog-title span {
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
}

.nav-fixed .menus-item span,
.nav-fixed .blog-title span {
  text-shadow: none;
}

.nav-fixed {
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 5px 6px -5px rgba(133, 133, 133, 0.6);
}

.nav-container {
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.nav-mobile-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.blog-title,
.nav-title {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.blog-title span {
  font-size: 18px;
  font-weight: bold;
}

.menus-item {
  position: relative;
  display: inline-block;
  margin: 0 0 0 0.875rem;
  cursor: pointer;
}

.menus-item span {
  transition: all 0.2s;
}

.nav-fixed .menu-btn:hover {
  color: #49b1f5 !important;
}

.menu-btn:hover:after {
  width: 100%;
}

.menus-item span:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  z-index: -1;
  width: 0;
  height: 3px;
  background-color: #80c8f8;
  content: "";
  transition: all 0.3s ease-in-out;
}

.menus-item:hover .menus-submenu {
  display: block;
}

.menus-submenu {
  position: absolute;
  display: none;
  right: 0;
  width: max-content;
  margin-top: 8px;
  box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  animation: submenu 0.3s 0.1s ease both;
}

.menus-submenu:before {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}

.menus-submenu span {
  line-height: 2;
  color: #4c4948 !important;
  text-shadow: none;
  display: block;
  padding: 6px 14px;
}

.menus-submenu span:hover {
  background: #4ab1f4;
}

/**
  导航子菜单淡入淡出
 */
@keyframes submenu {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    filter: none;
    transform: translateY(0);
  }
}

.submenu-item {
  font-size: 14px;
}
</style>