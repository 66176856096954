import dayjs from "dayjs";

export const DateFormat = {
  date: function (value){
    return dayjs(value).format("YYYY-MM-DD");
  },
  dateTime:function (value){
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss")
  }
}
